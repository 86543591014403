import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "../stylesheets/application.scss";
import "../stylesheets/style.scss";
// カノジョ
import "../stylesheets/girl.scss";
// 全国
import "../stylesheets/global.scss";
// アルバム
import "../stylesheets/album.scss";
// アイテム
import "../stylesheets/item.scss";
// 設定
import "../stylesheets/setting.scss";
// bootstrap
import 'bootstrap'
import '../src/application.scss'


Rails.start();
Turbolinks.start();
ActiveStorage.start();

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
